import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { Button } from 'react-bootstrap';

class Contact extends React.Component
{
    render()
    {
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="Contact">
                            <br/>
                            <h1>Contact</h1>
                            <br/>
                            <div>
                                <h3>Email:</h3>
                                <a href="mailto: milan.marocchi@gmail.com">
                                    milan.marocchi@gmail.com
                                </a>
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <h3>Phone:</h3>
                                +61 408 614 665
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                <h3>Linkedin:</h3>
                                <Button href="https://www.linkedin.com/in/milan-marocchi-3785b1215/" target="_blank">Linkedin</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
            </Container>
        );
    }
}

export default Contact;
