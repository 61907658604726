import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import personalContent from '../Content/PersonalContent';
import ProjectContainer from './ProjectContainer';

class Personal extends React.Component
{
    render()
    {
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="Personal">
                            <br/>
                            <h1>Personal Projects</h1>
                            <br></br> 
                            <br></br> 
                            <Container class="centered">
                                    <ProjectContainer projectTiles={personalContent}/>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

}

export default Personal;
