import './TextEditor.css';

const TextEditor = () => 
{
    return(
        <section id = "texteditor">
        <div class="container">
            <div class = "row">
                <div class = "col">
                    <br></br>
                    <h1>Text Editor</h1>
                    <div class="mt-5">
                        <img src="UniversityImages/Tiles/texteditor-cover.png" class="center-block" alt="..."/>
                    </div> 
                </div>
            </div>
            <div class = "row">
                <div class = "col text-body">
                    <div class = "font-weight-bold"><b>Task: </b></div>
                    Create a text editor that makes use of a plugin and scripting system to add aditional functionality. The text-editor also has to use internationalisation to 
                    be translated into another language and for file io (use of different encodings.) The two plugins required to be included with the program are a date plugin
                    that inserts the current date at the caret and a find plugin that searches for words within the opened document. There was also a script that was required that
                    would insert the 🙂 emoji into the text. More information can be found below in the brief.
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <a href="UniversityImages/Projects/SEC_2021s2_Assignment2_19780197.html" target = "_blank" class="btn btn-primary">Read Brief</a>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <div class = "font-weight-bold"><b>Program Overview: </b></div>
                        The program works by allowing the user to type anything into the text editor. From there they have access to the save and load buttons, which will
                        save or load. The save will let the user specify location and name as well as then being prompted for the encoding to save the file as. The loading functionality
                        will bring up a file dialog then prompt the user to select the type of file enconding for that particular file. The plugin and scripts can be loaded through 
                        the add plugin/script button then added by putting their path in. Once loaded the api will load a button if required other wise add whatever else is needed.
                        There is also a DSL file that is read on start up that allows for short cut keys to be applied. 
                            <div class = "spacer"></div>
                                <div class = "text-center">
                                    <img src = "UniversityImages/Projects/texteditor-plugin.png" class='center-block' alt="..."/>
                                </div>
                                <div class = "spacer"></div>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <div class = "font-weight-bold"><b>Code Overview:</b> </div>
                        This program makes use of gradle sub projects to organise each part of the application. All the code is in java or python. The core application, such as the saving and internationalisation
                        is all within its own subproject, then the API is within its own project. Lastly, all the plugins are their own sub project. The plugins are loaded using reflection as they
                        must be extended from the API classes. There is also a Native implementation of the Date plugin, which is written in C++ and runs natively. There are classes that reflect their 
                        own responsibilities and a few design patterns are used to ensure that everything is decoupled. The code is available to read to understand how everyting is put together in more depth.
                    <div class = "spacer"></div>
                    The code and program can be found below.
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body">
                        <a href = "https://drive.google.com/drive/folders/1N0esL8aKQNfmtBbNuKz207brsmDLLofl?usp=sharing" target = "_blank" class = "btn btn-primary">View Code</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TextEditor;