import './SuperJunkPop.css';

const SuperJunkPop = () => 
{
    return (
        <section id = "superjunkpop">
            <div class="container">
                <div class = "row">
                    <div class = "col">
                    <br/>
                    <h1>Super Junk Pop</h1>
                        <div class="mt-5">
                            <img src="PersonalImages/Projects/superjunkpop-cover.gif" class="center-block" alt="..."/>
                        </div> 
                    </div>
                </div>
                <div class = "row">
                    <div class = "col my-4">
                        <div class = "font-weight-bold"><b>Description:</b> </div>
                            Super Junk Pop is all about cleaning up all the junk lying around everywhere. The more junk you clean the higher your combo.
                            Becareful of your guns after you destory junk as they will try to attack you. There are various game modes where you 
                            can try and clean as much in a time period or try to get the largest score. There are also tons of characters to unlock
                            and hidden items to find. Play over four different maps and get the highest score you can and unlock all the achievements.
                            Game to be released shortly onto Steam.
                    </div>
                    </div>
                <div class = "row">
                    <div class = "col mb-4">
                        <img src="PersonalImages/Projects/superjunkpop-menu.gif" class = "center-block" alt="..."/>
                        <div class = "spacer"></div>
                            <div class = "font-weight-bold"><b>Controls:</b></div>
                            <li>Movement: Arrow Keys</li>
                            <li>Shoot: Z</li>
                            <li>Menu: Escape</li>
                            <div class = "spacer"></div>
                            <a href="https://drive.google.com/drive/folders/1jCt0qoWP73aBNyOxbl43OlbPg770lrnu?usp=sharing" class = "btn btn-primary" target="_blank">Download Game</a>
                            <div class = "spacer"></div>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col my-4">
                        <img src="PersonalImages/Projects/superjunkpop-play.gif" class = "center-block text-center" alt="..."/>
                        <div class = "spacer"></div>
                        <div class = "font-weight-bold"><b>Development:</b></div>
                        This game has been in development for 1 year and has gone over many different iterations. The game was developed so that a framework could be worked on and
                        tested with a game. The framework is all about rapid game prototyping and provides the programmer with various different functionality not native to Game
                        Maker Studio 2. It also makes use of various shaders to improve visuals. There are also plans to have online leaderboards hosted on our website. During development
                        there were struggles to get all the features required working within the framework, especially when the new update of GMS2 removed functionality that the framework was built from,
                        hence, for now it is running on an older version of GMS2, but plans are in place to port it to the new version. Watch out for the final release on Steam, with plans
                        to donate earnings to charities.
                        <div class = "spacer"></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SuperJunkPop;