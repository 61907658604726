import './Catventure.css';

const Catventure = () =>
{
    return (
        <section id = "catventure">
            <div class="container">
                <div class = "row">
                    <div class = "col">
                    <br></br>
                    <h1>Catventure</h1>
                        <div class="my-5">
                            <img src="PersonalImages/Tiles/catventure-cover.gif" class="center-block" alt="..."/>
                        </div> 
                    </div>
                    </div>
                <div class = "row">
                    <div class = "col my-4">
                        <div class = "font-weight-bold"><b>Description: </b></div>
                        Collect the gems!! Getting loot while looking cute. This game is about chilling with your cat mates and making fat stacks. Help your cat to escape from the dungeon. There are three types of items/friends to add to your chain. Coins, Spells and a cat friend. There are two types of things to avoid, spike shooting traps and enemy slimes. 
                            <div class = "spacer"></div>
                            <div class = "mt-5 text-center">
                                <img src="PersonalImages/Projects/catventure-title.gif" class = "center-block gif" alt="..."/>
                            </div>
                            <div class = "font-weight-bold"><b>Controls:</b></div>
                            <li>Movement: Arrow Keys</li>
                            <li>Use last item in chain: X</li>
                            <li>Drop last item in chain: Z</li>
                            <li>Toggle Pause: P</li>
                            <li>Return to title screen: Escape</li>
                            <li>Delete Save File: D</li>
                            <li>Toggle Mute: M</li>
                            <li>Start Game: Enter/Z</li>
                            <div class = "spacer"></div>
                            <a href="https://axolot-studio.itch.io/catventure" class = "btn btn-primary" target="_blank">Download Game</a>
                            <div class = "spacer"></div>
                            <div class = "mt-5 text-center">
                                <img src="PersonalImages/Projects/catventure-play.gif" class = "center-block gif" alt="..."/>
                            </div>
                            <div><b>Music:</b></div>
                            <br></br>
                            <div class="row">
                                <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1068784075&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true" class='center-block'></iframe>
                                <div style=
                                    {{fontSize: '10px',
                                        color: '#cccccc',
                                        lineBreak: 'anywhere',
                                        wordBreak: 'normal',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif',
                                        fontWeight: 100}}>
                                    <a href="https://soundcloud.com/axolot-music" title="Cove" target="_blank" style={{color: '#cccccc', textDecoration: 'none'}}>Cove</a>
                                    <a href="https://soundcloud.com/axolot-music/catventure" title="Ice Cream Boy and the Magic Bullet" target="_blank" style={{color: '#cccccc', textDecoration: 'none'}}>Ice Cream Boy and the Magic Bullet</a>
                                </div>
                            </div>
                <div class = "row">
                    <div class = "col my-4">
                        <div class = "font-weight-bold"><b>Development:</b></div>
                        This game was made for GMTK 2021, with most of the programming and game design being done by myself and Samuel Allen. Louis Schelfhout wrote the music and Samuel Allen did the sound effects. Sam also did all of the art. This game jam went really smoothly with the use of a framework that was developed throughout the year by Sam and myself. The theme for this game jam was joined together, hence, the idea of making all the items/friends add to your chain and follow you around. The music can be found above.
                        <div class = "spacer"></div>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </section> 
    );
}

export default Catventure;