import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './App.css'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import University from "./Components/University";
import Personal from "./Components/Personal";
import Contact from "./Components/Contact";
import About from "./Components/About";
import Project from "./Components/Project";


ReactDOM.render(
  <React.StrictMode>
    <div id="page-container">
      <div id="content-wrap">
        <Router>
          <Header />
          <Switch>
            <Route exact path="/" component={App} />
            <Route path="/University" component={University} />
            <Route path="/Personal" component={Personal} />
            <Route path="/Contact" component={Contact} />
            <Route path="/About" component={About} />
            <Route path="/Project" component={Project} />
          </Switch>
          <Footer />
        </Router>
      </div>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
