import React from 'react';
import {Button, Container, Row, Col} from 'react-bootstrap';
import './About.css';

class About extends React.Component
{
    render()
    {
        return (
            <section id="about">
                <Container>
                    <Row>
                        <Col>
                            <br/>
                            <h1>About Me</h1>
                            <div class = "about-content">
                                <div class="row">
                                    <div class="col my-4 text-body">
                                        My name is Milan; I am a PhD candidate in Electrical and Electronic Engineering, passionate about audio and signal processing.
                                        Having experience working in these fields from various jobs, as found below, I am pursuing further studies, undertaking
                                        a PhD in Electrical and Computer Engineering, specialising in machine learning and signal processing.
                                        I am also a sessional academic at Curtin University, tutoring various computing and electrical engineering units over almost three years.
                                        My interests include music, recording, audio, signal processing and machine learning.
                                      I also enjoy creating video games with my company, Axolot Studios, and working on developing software with my company, Cove Co.

                                        <br/>
                                        <br/>
                                        <Button href="Documents/CV.pdf" target="_blank">Milan's CV</Button>
                                        <br/>
                                        <br/>
                                      I am currently undertaking my PhD in Electrical Engineering, working on multichannel classification algorithms for detection of abnormal heart sounds that are indicative of cardiovascular diseases. My primary focus currently is developing dataset augmentation to enable SOTA classification models such as transformers or selective state-space models. This entails traditional augmentation using signal procesing techniques alongside diffusion models to extend singe channel datasets to multichannel data.
                                        <br/>
                                        <br/>
                                        I enjoy programming, so since 2021 (during my studies), to get more exposure to programming on complex tasks and to work in a professional environment I started <a href="https://coveco.io/" target="_blank">Cove Co.</a>for contract Software Engineering.
                                      I also cofounded <a href="https://axolot.studio/" target="_blank">Axolot</a> for game development. These companies enabled me to work on interesting projects and work on video games whilst I was in my third and fourth year of study.
                                      These projects involved working with python, MySQL and Javascript to create automation for various services such as reports. Games developed for Axolot were primariliy made for game jams, and can be found on <a href="https://axolot-studio.itch.io/" target="_blank">itch.io</a>.
                                        <br/>
                                        <br/>
                                      I was working for Dolby Laboratories until December 2023, in a casual position following the completion of my internship in Feburary 2023. In my internship I worked on Data Analytics and a front end interface for an audio system, utilising
                                      python, React, javascript, Databricks and C++. Currently I am working as QA engineer working on CI pipelines for MS12 Android and other QA responsibilities.
                                        Over the 2022 summer I participated in the vaction program with Fortescue as a Process Control Engineer, where I supported a plant working with PLCs and SCADA. I also did some work optimising a process within train loading.
                                      I also have previously worked for Perth Soccer Club and Optus Stadium during my initial years of university, mainly working as bar staff. I also have done private tutoring for ATAR Mathematics, ATAR Chemistry and ATAR Computer Science. This can all be found below
                                        in my CV. Below is my academic transcript for my Electrical and Electronic Engineering and Computer Science Double Degree.
                                        <br/>
                                        <br/>
                                        <Button onClick = {() => window.open("/Documents/transcript.pdf")}>Academic Transcript</Button>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }

}

export default About;
