import './BattleShip.css';

const BattleShip = () =>
{
    return(
        <section id = "battleship">
        <div class="container">
            <div class = "row">
                <div class = "col">
                    <br></br>
                    <h1>Battleship Program</h1>
                    <div class="mt-5">
                        <img src="UniversityImages/Projects/battleship.png" class="center-block" alt="..."/>
                    </div> 
                </div>
            </div>
            <div class = "row">
                <div class = "col text-body">
                    <div class = "font-weight-bold"><b>Task: </b></div>
                        To create a program in C, which reads in a board file and missile file, then allows the user to play one player battleship, 
                        where the objective is to sink all of the ships on the board with all the available missiles.
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <a href="UniversityImages/Projects/battleship-brief.pdf" target = "_blank" class="btn btn-primary">Read Brief</a>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <div class = "font-weight-bold"><b>Program Overview: </b></div>
            This program takes two command line arguments, one for the file containing the position of battle ships on the board 
            and the other containing the missile types and the order of missiles. The four missile types are; single, vertical line,
            horizontal line and splash. More information on these types of missiles can be found in the brief. 
                            <div class = "spacer"></div>
                                <div class = "text-center">
                                    <img src = "UniversityImages/Projects/battleship-options.png" class="center-block" alt="..."/>
                                </div>
                                <div class = "spacer"></div>
                                    The program begins with a menu which allows the user to; play the game with the current
                                    board file and missile file, display the contents of the missile file, create a new
                                    board file, or create a new missile file. These options can be seen above. The option to 
                                    create a new board file allows you to choose the size of the board from 1 by 1 to 12 by 12,
                                    then to fill it will ships in whatever positions you want. More information about the program
                                    can be found in the report below.    
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body mb-4">
                        <a href = "UniversityImages/Projects/battleship-report.pdf" target = "_blank" class = "btn btn-primary">Read Report</a>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <div class = "font-weight-bold"><b>Code Overview:</b> </div>
                        The program was written in C. A linked list data structure was also written for this program, as specified
                        in the brief to store the missiles. The other data is stored in arrays, some of which are dynamically resized
                        to emulate an array list data structure. This choice was made as the array contains the battle ships, and the ends
                        are not always the ones being accessed, hence due to the O(1) access of any item in an array this was used over 
                        O(N) for a linked list. A more indepth explaination of the code can be found in the report.
                    <div class = "spacer"></div>
        The code and user guide, along with the README file, can be found below.
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body">
                        <a href = "https://drive.google.com/drive/folders/1ix5ObZwFzj3YmpBV_rPO72N3aw16FoTP?usp=sharing" target = "_blank" class = "btn btn-primary">View Code</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BattleShip;