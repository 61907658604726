import './CovGuard.css';

const CovGuard = () => 
{
    return(
        <section id = "covguard">
        <div class="container">
            <div class = "row">
                <div class = "col">
                    <br></br>
                    <h1>Cov-Guard</h1>
                    <div class="mt-5">
                        <img src="UniversityImages/Tiles/Cov-Guard.png" class="center-block" alt="..."/>
                    </div> 
                </div>
            </div>
            <div class = "row">
                <div class = "col text-body">
                    <div class = "font-weight-bold"><b>Task: </b></div>
                    To create an embedded system on any topic. The topic picked was to create a check in station that will check peoples temperature, dispense sanitiser, make sure they are
                    wearing a facemask and to check their safe WA sign in. This had to be done using microcontrollers, sensors and actuators. It was chosen to use an Arduino and a 
                    Raspberry Pi as well as to allow for concurrent processing, where the raspberry pi would run the machine learning algorithms and the arduino would control the actuators. 
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <div class = "font-weight-bold"><b>Design: </b></div>
                        The design works as the arduino will communicate with the Raspberry pi through i2c to schedule when certain tasks should occur. There is an ultra sonic sensor, a motor 
                        as well as a camera, touch screen and various other devices that are all part of the system. The flow of the system works as follows; the raspberry pi will check if the person 
                        is wearing a face mask. If they are it will then load the thermal camera module. This module will send a signal to the arduino to start reading data from the thermal camera
                        which is then sent back to the raspberry pi for processing. Once the temperature is determined the raspberry pi sends a signal to the arduino to stop and will either display 
                        that the person must leave if their temperature is too high otherwise will continue. Next the user will be prompted to sign it with SafeWA by scanning the QR code or by entering 
                        in their details on the touch screen. The raspberry pi will then wait for this information or scan the users phone to validate their sign in. Lastly, the user will then be prompted 
                        to use the hand sanitiser and after putting their hand under the ultra sonic sensor the motor will activate and dispense sanitiser. 
                        The prototype can be seen below. This prototype is very basic and only include the 
                        mask detection and sanitiser dispensing. The full report that goes more in depth into the design can also be seen below, as well as a demo of the prototype.
                            <div class = "spacer"></div>
                                <div class = "text-center">
                                    <img src = "UniversityImages/Projects/Cov-Guard-concept.png" class='center-block' alt="..."/>
                                </div>
                                <div class = "spacer"></div>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <a href="https://drive.google.com/drive/folders/1m9cvmBhL9LQ1Qh0rlh82Mnu0WGDReNH7?usp=sharing" target = "_blank" class="btn btn-primary">View Report and Demo</a>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <div class = "font-weight-bold"><b>Prototype:</b> </div>
                        This program makes use of gradle sub projects to organise each part of the application. All the code is in java or python. The core application, such as the saving and internationalisation
                        is all within its own subproject, then the API is within its own project. Lastly, all the plugins are their own sub project. The plugins are loaded using reflection as they
                        must be extended from the API classes. There is also a Native implementation of the Date plugin, which is written in C++ and runs natively. There are classes that reflect their 
                        own responsibilities and a few design patterns are used to ensure that everything is decoupled. The code is available to read to understand how everyting is put together in more depth.
                    <div class = "spacer"></div>
                    The code and program can be found below.
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <div class = "font-weight-bold"><b>Code:</b> </div>
                        The code developed uses interrupts to trigger communication between the raspberry pi and the arduino. The mask detection model is also made from a face detection model,
                        then trained to detect masks from that model. The code for mask detection is multithreaded, this was done to make the camera feedback smoother, as the model takes around two
                        frames of 60fps to detect the mask. So by running the camera on its own thread and the detection on a separate thread it allows the camera feed to be smooth. The code is 
                        all explained within the report above. 
                    <div class = "spacer"></div>
                    The code for the machine learning model, raspberry pi and arduino from the demo can be found below.
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body">
                        <a href = "https://drive.google.com/drive/folders/1UwXBlbNUXL6n64YZtlB3aZNXpm9nRDxz?usp=sharing" target = "_blank" class = "btn btn-primary">View Code</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CovGuard;