import './CryptoGraph.css';

const CryptoGraph = () => 
{
    return (
        <section id = "cryptograph">
        <div class="container">
            <div class = "row">
                <div class = "col">
                    <br></br>
                    <h1>Crypto Graph</h1>
                    <div class="mt-5">
                        <img src="UniversityImages/Projects/cryptograph.png" class="center-block" alt="..."/>
                    </div> 
                </div>
            </div>
            <div class = "row">
                <div class = "col text-body">
                    <div class = "font-weight-bold"><b>Task: </b></div>
                        To create a program that allows for analysis or crypto-currency trading data. The data is from the binance API. The program is required to run in 
                        two modes; interactive and report. In interactive mode various options can be chosen. In report mode various statistics are displayed. More information
                        can be found in the brief.
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <a href="UniversityImages/Projects/cryptograph-brief.pdf" target = "_blank" class="btn btn-primary">Read Brief</a>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <div class = "font-weight-bold"><b>Program Overview: </b></div>
                        This program takes three command line arguments. These are for the asset file, which contains all the data on what assets
                        can trade with what. The trade file, which contains all the trade data and finally the asset info file, which contains
                        additional information on each asset that is not included within the binance API data. This data was optional to be read 
                        in, and was csv data. The program allows for the user to find various trade paths between assets, display the data of an asset
                        or trade path, as well as display various statistics on the whole data set. All the data is read in and stored in a Graph 
                        data structure that had to be made from scratch, as well as all other data structures used. These include, hashtables, linked lists
                        and heaps.
                        <div class = "text-center mt-4">
                            <img src = "UniversityImages/Projects/cryptograph-paths.png" class="center-block" alt="..."/>
                        </div>
                    </div>
                </div>    
                <div class = "row">
                    <div class = "col text-body mb-4">
                        One extra added feature was to find the shortest path between two assets, this was done using the Bellman-Ford 
                        algorithm. However, due to the presence of negative weight cycles that could occur in the graph, it was not always 
                        possible to find the shortest path, hence, instead this negative weight cycle would be displayed. This is shown above. The
                        data used in the image above was taken from the binance API on 30/10/2020 at 10:33. This would show 
                        paths that provide infinite loops were money could always be made from. However, as the data is static and only
                        represents what is read in when the program is launched, these negative weight cycles will not exist for that long
                        due to the exchange rates changing constantly. However, this still shows potential cycles that could be traded for 
                        large profit. More information can be found in the report below.
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body mb-4">
                        <a href = "UniversityImages/Projects/cryptograph-report.pdf" target = "_blank" class = "btn btn-primary">Read Report</a>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <div class = "font-weight-bold"><b>Code Overview: </b></div>
                            The program was written in java, using two packages, json-simple and junit4. The json package was used to allow for 
                            easier parsing of json files and junit4 was used to test harnesses could be written for each class. All the data structures
                            used had to be written from scratch. The graph used contains two hashtables, one for the nodes and another for the edges.
                            This was done as every node and edge has a unique code, which allows for it to be easily hashed, as well as giving O(1) 
                            for accessing the data within the graph, which allows for the program to be faster than using another data structure such 
                            as a linked list, with O(N) find. The test harnesses for each class made it a lot easier to create the program due to testing
                            each class before using them altogether making debugging a lot easier.
                    <div class = "spacer"></div>
        The code and user guide, along with the README file, can be found below.
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body">
                        <a href = "https://drive.google.com/drive/folders/1JefSO8_M42oMInT_YEmGuXuAX38ihbLc?usp=sharing" target = "_blank" class = "btn btn-primary">View Code</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CryptoGraph;