export const universityContent = [
    {
        name: 'Read More',
        title: 'Cov-Guard',
        img: 'UniversityImages/Tiles/Cov-Guard.png',
        text: 'This design is for a system that detects face masks, dispenses sanitiser, checks peoples temperature and safe WA sign in as a check in station for venues.',
        component: 7,
        project: 'uni'
    },
    {
        name: 'Read More' ,
        title: 'Text Editor',
        img: 'UniversityImages/Tiles/texteditor-cover.png',
        text: 'This program is a text editor that makes use of JNI, Reflection and jython to allow for scripting and plugins to be built for it. Internationalisation is also used for file io.',
        component: 6,
        project: 'uni'
    }, 
    {
        name: 'Read More' ,
        title: 'Similarity Checker',
        img: 'UniversityImages/Tiles/similarity-cover.png',
        text: 'Using threads and thread pools this program checks similarity between every file within a directory',
        component: 5,
        project: 'uni'
    }, 
    {
        name: 'Read More' ,
        title: 'Mars Rover Event Driven System',
        img: "UniversityImages/Tiles/MarsRover-cover.png",
        text: 'This program is the software system for a mars rover. It is event based and uses polling to wait for commands',
        component: 4,
        project: 'uni'
    },
    {
        name: 'Read More' ,
        title: 'Power Grid',
        img: 'UniversityImages/Tiles/PowerOOSE.png',
        text: 'Design patterns such as the composite pattern, factory pattern and strategy pattern have been used to model a power grid.',
        component: 3, 
        project: 'uni'
    },
    {
        name: 'Read More' ,
        title: 'Crypto Graph Program',
        img: 'UniversityImages/Tiles/cryptograph.png',
        text: 'This is a program that reads in binance API data on cryptocurrencies and then displays statistics and finds trade paths between certain assets.',
        component: 2,
        project: 'uni'
    },
    {
        name: 'Read More' ,
        title: 'Battleship Game',
        img: 'UniversityImages/Tiles/battleship.png',
        text: 'This is a single player battleship game, where one players tries to sink all of the ships on the board.',
        component: 1,
        project: 'uni'
    },
    {
        name: 'Read More' ,
        title: 'Sea Monkey Simulation',
        img: 'Universityimages/Tiles/pygame-shrimp.gif',
        text: 'This is a simulation of sea monkeys which can be altered with various parameters to change behaviours.',
        component: 0,
        project: 'uni'
    }]

export default universityContent;