import {Container, Row, Col} from 'react-bootstrap';
import './SeaMonkey.css';
import { Button } from 'react-bootstrap';


const SeaMonkey = () =>
{
    return (
        <section id = "pygame-shrimp">
        <div class="container">
        <div class = "row">
        <div class = "col">
        <br></br>
        <h1>Sea Monkey Simulation</h1>
            <div class="mt-5">
                <img src="UniversityImages/Projects/pygame-shrimp.gif" class="center-block" alt="..."/>
            </div> 
        </div>
        </div>
        <div class = "row">
        <div class = "col text-body">
            <div class = "font-weight-bold"><b>Task: </b></div>
            To create a simulation of brine shrimp, which allows for varying conditions, which will then be tested to see how they impact the overall simulation.
        </div>
        </div>
        <div class = "row">
        <div class = "col text-body my-4">
            <a href="UniversityImages/Projects/shrimp-brief.pdf" target = "_blank" class="btn btn-primary" rel='noopener noreferrer'>Read Brief</a>
        </div>
        </div>
        <div class = "row">
        <div class = "col text-body my-4">
            <div class = "font-weight-bold"><b>Program Overview: </b></div>
            This program takes 7 command line arguments. These represent the; width and height of the tank, 
            the number of brine shrimp eggs, the number of hours that the simulation will run for (
            the time scale is not 1 to 1). These also include the number of predator eggs, 
            as well as the salinity and temperature of the water.
            <div class = "spacer"></div>
            <div class = "text-center">
                <img src = "UniversityImages/Projects/shrimp-eggs.gif" class="center-block" alt="..."/>
            </div>
            <div class = "spacer"></div>
            The program begins with all the eggs being randomly placed within the 2 dimensions of the tank, 
            the brine shrimp will then grow and so will the predators. This can be seen in the above gif.
            The predators are able to eat the shrimp, and when the shrimp are fully matured are able 
            to mate with a shrimp of the opposite sex. Once the simulation has been completed statistics
            for the simulatrion will then be shown in the terminal. More information can be found in the report below.
        </div>
        </div>
        <div class = "row">
        <div class = "col text-body mb-4">
            <a href = "UniversityImages/Projects/shrimp-report.pdf" target = "_blank" class = "btn btn-primary" rel='noopener noreferrer'>Read Report</a>
        </div>
        </div>
        <div class = "row">
        <div class = "col text-body my-4">
            <div class = "font-weight-bold"><b>Code Overview:</b> </div>
            This program was written in python, using mainly the pygame package to allow for drawing sprites and 
            animating them, as well as playing music. The code was written in an object-oriented style as specified
            by the requirements in the assignment. It could now be re written in a much better way, to optimize performance
            and improve readability and maintainability by using design patterns and changing data structures used for storage
            or certain things. Some other things about the code are mentioned in the above report.
            <div class = "spacer"></div>
            The code and user guide, along with the README file, can be found below.
        </div>
        </div>
        <div class = "row">
        <div class = "col text-body">
            <Button onClick = {() => window.open("https://drive.google.com/drive/folders/1nTbzMRDUVZ3TeyOzDq24UIlXTqOTK-3H?usp=sharing")}>View Code</Button>
        </div>
        </div>
        </div>
        <br>
        </br>
        <br>
        </br>
        </section>
    );
}

export default SeaMonkey;