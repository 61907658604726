/** Contains the actual project with all information */
/** Uses the componsents passed in to populate this page to reduce routing to tons of pages */

import React from 'react';
import { useLocation } from 'react-router-dom';
import BattleShip from '../Content/UniversityProjects/BattleShip';
import SeaMonkey from '../Content/UniversityProjects/SeaMonkey';
import CryptoGraph from '../Content/UniversityProjects/CryptoGraph';
import PowerUsage from '../Content/UniversityProjects/PowerUsage';
import IceCreamBoy from '../Content/PersonalProjects/IceCreamBoy';
import FarmWitch from '../Content/PersonalProjects/FarmWitch';
import Catventure from '../Content/PersonalProjects/Catventure';
import SuperJunkPop from '../Content/PersonalProjects/SuperJunkPop';
import BushFireWatch from '../Content/PersonalProjects/BushFireWatch';
import MarsRover from '../Content/UniversityProjects/MarsRover';
import Similarity from '../Content/UniversityProjects/Similarity';
import TextEditor from '../Content/UniversityProjects/TextEditor';
import CovGuard from '../Content/UniversityProjects/CovGuard';

const Project = (props) =>
{
    const UniProjectArr = [
        <SeaMonkey/>,
        <BattleShip/>,
        <CryptoGraph/>,
        <PowerUsage/>,
        <MarsRover/>,
        <Similarity/>,
        <TextEditor/>,
        <CovGuard/>
    ]

    const PersonalProjectArr = [
        <IceCreamBoy/>,
        <FarmWitch/>,
        <Catventure/>,
        <SuperJunkPop/>,
        <BushFireWatch/>
    ]

    var componentIndex;
    var component;

    const location = useLocation();

    if (location.component != undefined)
    {
        var componentIndex = location.component[0];

        if (location.component[1] === "uni")
        {
            var component = UniProjectArr[componentIndex]
        }
        else
        {
            var component = PersonalProjectArr[componentIndex]
        }
    }


    return (
        <div>
            {component}
        </div>
    );
}

export default Project;