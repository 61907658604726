import React from 'react';
import './App.css';
import {Button, Container, Row, Col} from "react-bootstrap";
import {LinkContainer, Link} from 'react-router-bootstrap';
import LinkButton from './Components/LinkButton';

class App extends React.Component
{
    render()
    {
        return (
            <section id="app">
                <div class="container">
                    <br></br>
                    <h1>About Me</h1>
                    <div class="row">
                        <div class="col text-body my-4">
                            My name is Milan; I am a PhD candidate in Electrical and Electronic Engineering, passionate about audio and signal processing.
                            Having experience working in these fields from various jobs, as found below, I am pursuing further studies, undertaking
                            a PhD in Electrical and Computer Engineering, specialising in machine learning and signal processing.
                            I am also a sessional academic at Curtin University, tutoring various computing and electrical engineering units over almost three years.
                            My interests include music, recording, audio, signal processing and machine learning.
                            I also enjoy creating video games with my company, Axolot Studios, and working on developing software with my company, Cove Co.
                            <br/>
                            <br/>
                                <LinkButton name="About" route="/About"/>
                            <br/>
                            <br/>
                        </div>
                    </div>
                    <div class="row">
                        <h1>Work Experience</h1>
                        <div class="col text-body my-4">
                                <div>
                                    <b>Dolby Laboratories Engineer (Casual) (Feb 2023 - Dev 2023)</b>: QA Engineer for MS12 Android. Working on CI pipelines and other QA responsibilities, using python, bash, java, C/C++.
                                </div>
                                <br></br>
                                <div>
                                  <b>Dolby Laboratories Intern (Nov 2022 - Feb 2023)</b>: Working on data collection from embedded system and data analytics, using python, C++, Reactjs and databricks.
                                </div>
                                <br></br>
                                <div>
                                  <b>FMG 2021/2022 Vacation Program Process Control Engineer (Nov 2021 - Feb 2023):</b> Optimising the purge in the train load out. Supporting plant operations. Working with SCADA and GE PLCs.
                                </div>
                                <br></br>
                                <div>
                                  <b>Curtin Sessional Academic (2022 - ongoing):</b> Tutoring various first and second year computer labs for the units Fundamentals of Programming, Data Structures and Algorithms, Introduction to Software Engineering, Unix and C Programming,
                                       Object Oriented Software Engineering, Design and Analysis of Algorithms and Software Architecture and Extensible Design.
                                       Tutoring for third year Electrical Engineering labs such as for Digital Signal Processing and Communications Engineering.
                                </div>
                                <br></br>
                                <div>
                                    <b>Software Engineering Consulting:</b> Making software for report automation from survey data. Creating websites. Air-Printing documents from the results of javascript questionaires.
                                </div>
                            </div>
                    </div>
                    <div class="row">
                        <h1>Publications</h1>
                        <div class="col text-body my-4">
                            <b>M. Marocchi</b>, L. Abbott, M. Fynn, Y. Rong, and K. Mandana. "Diffusion-Based Conditional Phonocardiogram Generation: Multichannel Audio from a Single Channel Source", <em>Australian Biomedical Engineering Conference</em>, Melbourne, Australia, <b>Nov. 17-20, 2024</b>.
                        </div>
                        <div class="col text-body my-4">
                            <b>Marocchi, M.</b>; Abbott, L.; Rong, Y.; Nordholm, S.; Dwivedi, G. "Abnormal Heart Sound Classification and Model Interpretability: A Transfer Learning Approach with Deep Learning." <em>J. Vasc. Dis.</em> <b>2023</b>, 2, 438-459. <a href="https://doi.org/10.3390/jvd2040034">https://doi.org/10.3390/jvd2040034</a>
                        </div>
                    </div>
                    <div class="row">
                        <h1>Awards</h1>
                        <div class="col text-body my-4">
                            <div>
                                <b>Western Power Prize 2022:</b> For the second highest weighted average marks in the Electrical and Electronic Engineering course. 
                            </div>
                            <br></br>
                            <div>
                                <b>Nuheara Prize 2022:</b> For the highest aggregate marks in Signals and Systems and Digitial Signal Processing
                            </div>
                            <br></br>
                            <div>
                                <b>Vice-Chancellors List 2021 Semester 2:</b> For a semester average within the top 1% of students.
                            </div>
                            <br></br>
                            <div>
                                <b>PCBeTheChange Winners 2021:</b> Won the PCBeTheChange International competition for desiging a PCB.
                            </div>
                            <br></br>
                            <div>
                                <b>Curtin Letter of Commendation 2019-2023:</b> For an average semester mark greater than 80.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            );
    }
}

export default App;
