import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css';
import { Container, Row, Col } from 'react-bootstrap';

class Footer extends React.Component
{
    render() {
        return(
              <footer className="footer">
              <Row>
              <Col/>
              <Col/>
              <Col className="padding"><h5>Developed by Milan Marocchi</h5>
                                        <h6>+61 408 614 665</h6>
                                        <a className="email" href="mailto: milan.marocchi@gmail.com">milan.marocchi@gmail.com</a></Col>
          </Row>
          </footer>
        )
    }
}

export default Footer;
