import './BushFireWatch.css'

const BushFireWatch = () => 
{
    return (
        <section id = "bushfirewatch">
            <div class="container">
                <div class = "row">
                    <div class = "col">
                    <br/>
                    <h1>Fire Detection PCB</h1>
                        <div class="mt-5">
                            <img src="PersonalImages/Tiles/fire_design_text.png" class="center-block" alt="..."/>
                        </div> 
                    </div>
                </div>
                <div class = "row">
                    <div class = "col my-4">
                        <div class = "font-weight-bold"><b>Description:</b> </div>
                        For the PCBeTheChange competition, it was required that teams would create a solution to an environmental issue local to your team. I was in a team with three 
                        other people; Anton Rieutskyi, Harison Outram and Jason Kim. Our team was Bush Fire Watch Aus and we came up with the Fire Detection PCB. The purpose of this
                        design was to alert people of bush fires. Our team ended up winning the <a href="https://resources.altium.com/p/pcbethechange-student-design-competition-winners-announced-upverter-education" target="_blank">competition.</a>
                        
                    </div>
                </div>
                <div class = "row">
                    <div class = "col mb-4">
                        <img src="PersonalImages/Projects/pcbdesign.gif" class = "center-block" alt="..."/>
                        <div class = "spacer"></div>
                            <div class = "font-weight-bold"><b>Design:</b></div>
                            The PCB is a small 
                            PCB that contains sensors for CO2, humidity temperature and wind speed, as shown above. The main controller is an Arduino Portena H7, as required by the competition. The 
                            design would use data gathered from sensors as well as communications to communicate with other of the same devices and use machine learning to determine
                            where bush fires are if there are any, so that it can alert people nearby of the bush fire so they can either evacuate or try to put it out. 
                            <div class = "spacer"></div>
                            <a href="https://drive.google.com/drive/folders/1rms4imvlQfQDcDncVae0gxf7pxmc1vZp?usp=sharing" class = "btn btn-primary" target="_blank">View Design Documents</a>
                            <div class = "spacer"></div>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col my-4">
                        <div class="row">
                            <div class="col my-4 center-block">
                                <img src="PersonalImages/Projects/PCB_front.jpg" class = "center-block text-center" alt="..."/>
                            </div>
                            <div class="col my-4 center-block" >
                                <img src="PersonalImages/Projects/PCB_back.jpg" class = "center-block text-center" alt="..."/>
                            </div>
                        </div>
                        <div class = "spacer"></div>
                        <div class = "font-weight-bold"><b>Further Development:</b></div>
                        After winning the competition, the design was refined with the help of Andrew Smith, an engineer from Altium. The C02 sensor was also added to the board instead of being attached through a 
                        header. The design can still be improved by adding solar instead of batteries as well as potentially using other sensors. As of now there are no plans 
                        to create this as a product, but, there is always the option. All that would need to be finalised is the code to run on the devices as well as testing and 
                        field testing. The final PCB was then manufactured and can be seen above.
                        <div class = "spacer"></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BushFireWatch;