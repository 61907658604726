/** Contains all the single projects for a section */

import React from 'react';
import ProjectTile from './ProjectTile';


class ProjectContainer extends React.Component
{
    constructor(props)
    {
        // Pass in an array of props to be shown
        super(props);
    }

    render ()
    {
        return (
            <div className="projectContainer">
                {this.props.projectTiles.map((projectTile) => 
                    <div className="ProjectTile">
                        <ProjectTile 
                            img={projectTile.img} 
                            title={projectTile.title} 
                            text={projectTile.text} 
                            name={projectTile.name} 
                            component={projectTile.component}
                            project={projectTile.project}
                        />
                        <br/>
                        <br/>
                    </div>
                )}
            </div>
        );
    }
}

export default ProjectContainer;