import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import ProjectContainer from './ProjectContainer';
import ProjectTile from './ProjectTile';
import universityContent from '../Content/UniversityContent';

class University extends React.Component
{
    render ()
    {
        return (
            <Container>
                <Row>
                    <Col>
                        <div className="University">
                            <br/>
                            <h1>University Projects</h1>
                            <br/>
                            <br/>
                            <Container class="centered">
                                    <ProjectContainer projectTiles={universityContent}/>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default University;
