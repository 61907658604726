import './MarsRover.css';

const MarsRover = () => 
{
    return(
        <section id = "marsrover">
        <div class="container">
            <div class = "row">
                <div class = "col">
                    <br></br>
                    <h1>Mars Rover Event Driven System</h1>
                    <div class="mt-5">
                        <img src="UniversityImages/Tiles/MarsRover-cover.png" class="center-block" alt="..."/>
                    </div> 
                </div>
            </div>
            <div class = "row">
                <div class = "col text-body">
                    <div class = "font-weight-bold"><b>Task: </b></div>
                        To create a program that will run on a mars rover to respond to various commands sent to it from Earth. The system is to use pre existing classes that control various 
                        hardware, but to manage these to perform various tasks under certain conditions when the associated command is sent. This was done through the use of two main design patterns.
                        These were the observer pattern, to make it event driven and the state pattern, to enforce different states depending on what actions the rover was performing.
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <a href="UniversityImages/Projects/OOSE_2021s1_Assignment2.md_19780197.html" target = "_blank" class="btn btn-primary">Read Brief</a>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <div class = "font-weight-bold"><b>Program Overview: </b></div>
                        This program makes use of the state pattern to separate each task it is performing or no task at all to it's own state, so that what it can do
                        or can't do in this state can be enforced by the class without the need of if statements and to decouple it's state from the actions. The observer
                        pattern is also used throughout to separate each part of the system. This allows for it to be extensible and have more features easily added in the 
                        future. It also makes use of generics for this same reason. The UML diagram can be seen below as well as the criteria document explaining these 
                        design choices in furhter depth.
                        <div class = "text-center mt-4">
                            <img src = "UniversityImages/Projects/UML.png" class="center-block" alt="..."/>
                        </div>
                    </div>
                </div>    
                <div class = "row">
                    <div class = "col text-body mb-4">
                        <a href = "UniversityImages/Projects/criteria.txt" target = "_blank" class = "btn btn-primary">Read Criteria</a>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <div class = "font-weight-bold"><b>Code Overview: </b></div>
                            THe code was written in C# and makes use of various patterns and classes as seen from the UML. The overall code design is overly complex
                            and could have used a lot less of the observer pattern, however, as it was assumed much more funcitonality would be added, the observer pattern
                            was used in order to add more observers to react depending on what the rover was doing. 
                    <div class = "spacer"></div>
        The code and UML diagram, along with the README file, can be found below.
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body">
                        <a href = "https://drive.google.com/drive/folders/1w3UNsksq1VIJm5QAEr8Zg1xCoD0qPTAl?usp=sharing" target = "_blank" class = "btn btn-primary">View Code</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default MarsRover;