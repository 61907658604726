import './FarmWitch.css';

const FarmWitch = () =>
{
    return (
        <section id = "farmwitch">
            <div class="container">
                <div class = "row">
                    <div class = "col">
                    <br/>
                    <h1>Farm Witch</h1>
                        <div class="mt-5">
                            <img src="PersonalImages/Tiles/farmwitch-cover.gif" class="center-block" alt="..."/>
                        </div> 
                    </div>
                </div>
                <div class = "row">
                    <div class = "col my-4">
                        <div class = "font-weight-bold"><b>Description:</b> </div>
                            A game where you must protect your plants from all sorts of evil trying to take it away. You have magic in order
                            to help protect you. Some of the magic includes various types of magic missiles and a magic sword. Are you able to 
                            survive and harvest all your plants? The current playable version of the game is an old but working build, the current 
                            build is not playable as it is being updated to the new version of Game Maker Studio 2.
                    </div>
                </div>
                <div class = "row">
                    <div class = "col mb-4">
                        <img src="PersonalImages/Projects/farmwitch-combat.gif" class = "center-block" alt="..."/>
                        <div class = "spacer"></div>
                            <div class = "font-weight-bold"><b>Controls:</b></div>
                            <li>Movement: WASD</li>
                            <li>Change HotBar: Q</li>
                            <li>Inventory: E</li>
                            <li>Use Item/Magic: Left Mouse Button</li>
                            <li>Options: Escape</li>
                            <div class = "spacer"></div>
                            <a href="https://drive.google.com/drive/folders/1VxiSA6FBjxra1CcbE0UTr1zy2VRCcFug?usp=sharing" class = "btn btn-primary" target="_blank">Download Game</a>
                            <div class = "spacer"></div>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col my-4">
                        <img src="PersonalImages/Projects/farmwitch-plant.gif" class = "center-block text-center" alt="..."/>
                        <div class = "spacer"></div>
                        <div class = "font-weight-bold"><b>Development:</b></div>
                        This game is still in development, and the first pre alpha is scheduled to be release shortly. I am developing it with Sam Allen. Development has been
                        done quite slowly over 2 years, with more stuff being added when ever we both have free time to add more to it. It is written in Game Maker studio 2.
                        So far there have been difficulties in development with getting some features to work as intended, one of which recently being getting surface shaders 
                        to draw over a certain portion of the screen. This has now been solved and has allowed for the ability to use any shader over certain parts of the screen.
                        The planting feature also went through many iterations, starting with a basic slow planting feature, this has now been changed so it is possible to quickly
                        plant many plants at once with effects as you throw the seed to the tile it is being planted on.
                        <div class = "spacer"></div>
                        <a href="https://drive.google.com/drive/folders/1dZuoZOWX0O6vchksrPTBH0aR18y6FJ9i?usp=sharing" class = "btn btn-primary" target="_blank">View Code</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default FarmWitch;