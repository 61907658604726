export const personalContent = [
    {
        name: 'Read More',
        title: 'Fire Detection PCB',
        img: 'PersonalImages/Tiles/fire_design_text.png',
        text: 'This design is for a system that detects face masks, dispenses sanitiser, checks peoples temperature and safe WA sign in as a check in station for venues.',
        component: 4,
        project: 'personal'
    },
    {
        name: 'Read More',
        title: 'Super Junk Pop',
        img: "PersonalImages/Projects/superjunkpop-cover.gif",
        text: 'Super Junk Pop is all about cleaning up Australia by destroying the junk left all around',
        component: 3,
        project: 'personal'
    }, 
    {
        name: 'Read More' ,
        title: 'Catventure',
        img: "PersonalImages/Tiles/catventure-cover.gif",
        text: 'Catventure is a game all about cats. You play as a cat trying to escape a dungeon and collect treasure and friends along the way.',
        component: 2,
        project: 'personal' 
    }, 
    {
        name: 'Read More' ,
        title: 'Farm Witch',
        img: "PersonalImages/Tiles/farmwitch-cover.gif",
        text: 'This is a game where you play as a witch that is able to cast magic spells to aid in defendings her crops.',
        component: 1,
        project: 'personal'
    },
    {
        name: 'Read More' ,
        title: 'Ice Cream Boy and the Magic Bullet',
        img: 'PersonalImages/Tiles/icecreamboy-cover.png',
        text: 'In this game you play as an ice cream boy, with a magic bullet to get through different levels and protect yourself against enemies.',
        component: 0,
        project: 'personal'
    }
    ]

export default personalContent;