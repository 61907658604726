import './IceCreamBoy.css';

const IceCreamBoy = ()=> {
    return(
        <section id = "icecreamboy">
            <div class="container">
                <div class = "row">
                    <div class = "col">
                    <br></br>
                    <h1>Ice Cream Boy and The Magic Bullet</h1>
                        <div class="my-5">
                            <img src="PersonalImages/Tiles/icecreamboy-cover.png" class="center-block" alt="..."/>
                        </div> 
                    </div>
                    </div>
                <div class = "row">
                    <div class = "col my-4">
                        <div class = "font-weight-bold"><b>Description: </b></div>
                    A game about an Ice Cream Boy who meets a special gun which can shoot magic bullets. This gun has a mind of its own but to survive you must work together!
                    Here is some of the magical effects each bullet can have:
                            <div class = "spacer"></div>
                            <li>"No Effect" :    A regular bullet.</li>
                            <li>"Useless" :    Does no damage.</li>
                            <li>"Move Towards" :    Will follow something in the room.</li>
                            <li>"New Bullets" :    Will create a number of new bullets.</li>
                            <li>"Spawner" :    Will spawn ammo or an enemy where the bullet is.</li>
                            <li>"Piercer" :    Will hit and go through a certain number of enemies.</li>
                            <div class = "spacer" ></div>
                            <div class = "mt-5 text-center">
                                <img src="PersonalImages/Projects/icecreamboy.gif" class = "center-block gif" alt="..."/>
                            </div>
                            <div class = "font-weight-bold"><b>Controls:</b></div>
                            <li>Movement: WASD</li>
                            <li>Shoot: Left Mouse Button</li>
                            <li>Options: Escape</li>
                            <div class = "spacer"></div>
                            <a href="https://cove-co.itch.io/icecream-boy-and-th" class = "btn btn-primary" target="_blank">Download Game</a>
                            <div class = "spacer"></div>
                            <div class = "mt-5 text-center">
                                <img src="PersonalImages/Projects/icecreamboy-death.gif" class = "center-block gif" alt="..."/>
                            </div>
                            <div class = "spacer"></div>
                            <div class = "font-weight-bold"><b>Music: </b></div>
                            <div class = "spacer"></div>
                            <iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1092436765&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true" class='center-block'></iframe>
                            <div style=
                                {{fontSize: '10px',
                                 color: '#cccccc',
                                 lineBreak: 'anywhere',
                                 wordBreak: 'normal',
                                 overflow: 'hidden',
                                 whiteSpace: 'nowrap',
                                 textOverflow: 'ellipsis',
                                 fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif',
                                 fontWeight: 100}}>
                                <a href="https://soundcloud.com/cove-foundry" title="Cove" target="_blank" style={{color: '#cccccc', textDecoration: 'none'}}>Cove</a>
                                <a href="https://soundcloud.com/cove-foundry/sets/ice-cream-boy-and-the-magic-bullet" title="Ice Cream Boy and the Magic Bullet" target="_blank" style={{color: '#cccccc', textDecoration: 'none'}}>Ice Cream Boy and the Magic Bullet</a>
                            </div>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col my-4">
                        <div class = "font-weight-bold"><b>Development:</b></div>
                        This game was developed in a team of three, with Sam Allen, Louis Schelfhout and myself. Sam and I did the programming and design of the game with Louis
                        writing the music and sound effects. The game was made in game maker studio 2 over a period of two days. The theme for the game jam was out of control 
                        hence the main feature of random bullets was used in order to conform to that theme. Some challenges that were involved were making 
                        sure the code was written in a way that would provide extensibility, as the main feature of the game was these magic bullets, and for more to be added
                        it had to be written in a way that would allow it. The code can be found below.
                        <div class = "spacer"></div>
                        <a href="https://drive.google.com/drive/folders/1kXTWsZ05EhFIDnVkACT2gjOZotItbjxQ?usp=sharing" class = "btn btn-primary" target="_blank">View Code</a>
                    </div>
                </div>
            </div>
        </section> 
    );
}

export default IceCreamBoy;