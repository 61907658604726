import React from 'react';
import {Navbar, Container, Nav} from 'react-bootstrap';
import {IndexLinkContainer} from 'react-router-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Header.css';

class Header extends React.Component
{
    render() {
        return(
            <>
                <Navbar expand="lg" bg="dark" variant="dark">
                    <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                            <IndexLinkContainer to="/">
                                <Nav.Link><h4>Home</h4></Nav.Link>
                            </IndexLinkContainer>
                            <IndexLinkContainer to="/University">
                                <Nav.Link><h4>University Projects</h4></Nav.Link>
                            </IndexLinkContainer>
                            <IndexLinkContainer to="/Personal">
                                <Nav.Link><h4>Personal Projects</h4></Nav.Link>
                            </IndexLinkContainer>
                            <IndexLinkContainer to="/Contact">
                                <Nav.Link><h4>Contact</h4></Nav.Link>
                            </IndexLinkContainer>
                        </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </>
        )
    }
}

export default Header;