import './PowerUsage.css';

const PowerUsage = () =>
{
    return (
        <section id = "assignment1c#">
        <div class="container">
            <div class = "row">
                <div class = "col">
                    <br></br>
                    <h1>Power Usage Program</h1>
                    <div class="mt-5">
                        <img src="UniversityImages/Projects/PowerOOSE.png" class="center-block" alt="..."/>
                    </div>
                </div>
            </div>
            <div class = "row">
                <div class = "col text-body">
                    <div class = "font-weight-bold"><b>Task: </b></div>
                    To create a program in java, C#, python or C++, that allows for loading and displaying of power usage from different layouts,
                    of cities. These can contain area and leaf nodes, where area nodes contain multiple other nodes. There are two forms of loading a
                    model. This is through random generation or through reading a file. The model can then be displayed to the screen or written to a file.
                    These will be determined by input arguments.
                </div>
            </div>
            <div class = "row">
                <div class = "col text-body my-4">
                    <a href="UniversityImages/Projects/OOSE_2021s1_Assignment1.md_19780197.html" target = "_blank" class="btn btn-primary">Read Brief</a>
                </div>
            </div>
            <div class = "row">
                <div class = "col text-body my-4">
                    <div class = "font-weight-bold"><b>Program Overview: </b></div>
                    This program follows the brief and hence, allows for loading and displaying of these models of power usage.
                    It also displays a final overview on all the power used for each power category, as well as displaying the areas
                    in a nicely formatted tree. The program makes use of multiple design patterns, these involve the factory pattern,
                    strategy pattern and the composite pattern. The composite pattern is used for the areas and nodes to allows for the
                    storage of the model (data.) This also allows for decoupled recursive commands over the model. The strategy pattern
                    is used in combination with the factory pattern to create the class corresponding to the arguments. Hence, the appropriate
                    loader is created by the factory depending on the input arguments.
                    <div class = "spacer"></div>
                    There is more detail on all the design decision that can be found below, as this was a requirement for the assignment.
                    This program could have been written differently to make use of the visitor pattern to have more parts of the system decoupled.
                    Especially for the traversal and performing of operations over the model which made use of the composite pattern.
                </div>
            </div>
            <div class = "row">
                <div class = "col text-body mb-4">
                    <a href = "UniversityImages/Projects/criteriaAssignment1.txt" target = "_blank" class = "btn btn-primary">Read Criteria Answers</a>
                </div>
            </div>
            <div class = "row">
                <div class = "col text-body my-4">
                    <div class = "font-weight-bold"><b>Code: </b></div>
                    All the code, readme and assignment brief can be found below.
                </div>
            </div>
            <div class = "row">
                <div class = "col text-body">
                    <a href = "https://drive.google.com/drive/folders/1VOdgyPSXqOhKoQc_oKT9Auz6wkAdW17m?usp=sharing" target = "_blank" class = "btn btn-primary">View Code</a>
                </div>
            </div>
        </div>
        </section>
    );
}

export default PowerUsage;