/** Contains all the code for a single project tile (to be displayed) */

import React from 'react';
import { Card } from 'react-bootstrap';
import LinkButton from './LinkButton';

class ProjectTile extends React.Component
{
    constructor(props)
    {
        // Pass in an array of props to be shown
        super(props);
    }

    render ()
    {
        return (
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                <Card style={{ width: '100%'}}>
                <Card.Body>
                    <Card.Img variant="top" src={this.props.img} />
                    <Card.Text>
                        <h3>{this.props.title}</h3>
                        {this.props.text}
                    </Card.Text>
                    <LinkButton name={this.props.name} route='/Project' component={this.props.component} project={this.props.project}/>
                </Card.Body>
                </Card>
            </div>
        );
    }
}

export default ProjectTile;
