import './Similarity.css';

const Similarity = () => 
{
    return (
        <section id = "similarity">
        <div class="container">
            <div class = "row">
                <div class = "col">
                    <br></br>
                    <h1>Similarity Checker</h1>
                    <div class="mt-5">
                        <img src="UniversityImages/Tiles/similarity-cover.png" class="center-block" alt="..."/>
                    </div> 
                </div>
            </div>
            <div class = "row">
                <div class = "col text-body">
                    <div class = "font-weight-bold"><b>Task: </b></div>
                        With the use of Threads and Thread Pools, as well as other threading concepts such as blocking queues, create a program that compares every
                        file within a directory with each other and then displays the similarity to the GUI as well as logging it to a file. This was all done in
                        Kotlin, as the task had to be done in a JVM language. More information can be found in the brief below.
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <a href="UniversityImages/Projects/SEC_2021s1_Assignment1.md_19780197.html" target = "_blank" class="btn btn-primary">Read Brief</a>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <div class = "font-weight-bold"><b>Program Overview: </b></div>
                        This program will let the use select a folder using the file dialog. The program then locates every single file inside this directory recursively,
                        and makes note of the valid ones, that have the designated file extension. These are then passed to a thread pool which runs all the comparisons.
                        From here they are then sent the data from the comparisons to a single logging thread that logs all the results to the output csv file. There is also
                        another logging thread, which logs any errors to an error file. The report which goes in depth to the code and architecural decisions for scaling 
                        this program can be found below.
                            <div class = "spacer"></div>
                                <div class = "text-center">
                                    <img src = "UniversityImages/Projects/similarity-done.png" class="center-block" alt="..."/>
                                </div>
                                <div class = "spacer"></div>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body mb-4">
                        <a href = "UniversityImages/Projects/SECAssignment1.pdf" target = "_blank" class = "btn btn-primary">Read Report</a>
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body my-4">
                        <div class = "font-weight-bold"><b>Code Overview:</b> </div>
                        This program was written in Kotlin and makes use of many java classes, both for multithreading as well as for the GUI, as javafx has been used.
                        There is also thread communication between various threads, done in the form of calling another thread. This is only done with passing the information required.
                        And threads only pass to the threads that require the information they have created. For example the logger thread only recieves similarity scores from the 
                        thread pool which does the similarity checking. There are also classes that handle each thread/thread pool. The Logger class handles the logging thread, the Finder
                        class handles the Finder thread and the FileComparer class handles the threadpool as to reduce the complexity of the program by having each class responsible for it's own
                        thread. There is also communication from the FileComparer to the GUI thread to display the required information. More information can be found in the report above.
                    <div class = "spacer"></div>
                    The code and program can be found below.
                    </div>
                </div>
                <div class = "row">
                    <div class = "col text-body">
                        <a href = "https://drive.google.com/drive/folders/1LyEYkArXqMK4jMulSDaSeiIjIs2euKcn?usp=sharing" target = "_blank" class = "btn btn-primary">View Code</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Similarity;